/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"
import React from "react"
import { CartProvider } from "./src/context/CartContext"

export const wrapRootElement = ({ element }) => {
  console.log("Element wrap root: ", element)
  return <CartProvider>{element}</CartProvider>
}
