import React, { createContext, useContext, useState, useEffect } from "react";
// import { useMixpanel } from "gatsby-plugin-mixpanel"

const defaultState = {
    items: [],
}

const CartContext = createContext(defaultState);

export const CartProvider = ({ children }) => {
    // const mixpanel = useMixpanel()

    const [items, setItems] = useState(defaultState.items);

    useEffect(() => {
        const cartItems = localStorage.getItem('cartItems');
        if (cartItems) {
            setItems(JSON.parse(cartItems));
        }
    }, [])

    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(items));
    }, [items])

    const itemAlreadyExists = (item) => {
        return items.find(itemInItems => {
            if (item.product.minLength === null) {
                console.log('Min length equals to null')
                return itemInItems.product.id === item.product.id
            }
            
            return (
              itemInItems.product.id === item.product.id &&
              itemInItems.product.minLength === item.product.minLength &&
              itemInItems.longitude === item.longitude
            )
        })
    }

    const addOrRemoveItem = (item) => {
        if(!itemAlreadyExists(item)) {
            setItems([...items, item]);
            // mixpanel.track(`Producto agregado a carrito: ${item.product.name}`)
        } else {
            const updatedItems = items.filter(itemInItems => {
                if (item.product.minLength !== null) {
                    return (
                      itemInItems.product.id !== item.product.id ||
                      itemInItems.product.minLength !== item.product.minLength ||
                      itemInItems.longitude !== item.longitude
                    )
                  }

                return itemInItems.product.id !== item.product.id
            });
            setItems(updatedItems);
            // mixpanel.track(`Producto eliminado del carrito: ${item.product.name}`)
        }
    };

    const updateItemQuantity = (item) => {
        if(!itemAlreadyExists(item)) {
            return
        }

        const updatedItems = items.map(itemInItems => {
            if(item.product.minLength === null) {
                if(itemInItems.product.id === item.product.id) {
                    return {
                        ...itemInItems,
                        quantity: item.quantity
                    }
                }
            }

            if (itemInItems.product.id === item.product.id &&
                itemInItems.product.minLength === item.product.minLength &&
                itemInItems.longitude === item.longitude) {
                    return {
                        ...itemInItems,
                        quantity: item.quantity
                    }
                }

            return itemInItems;
        });

        setItems(updatedItems);
    }

    const updateItemLongitude = (item) => {
        console.log(item)
        console.log(items)
        var itemExists = items.find(itemInItems => {
            if (item.product.minLength === null) {
                return itemInItems.product.id === item.product.id
            }
            
            return (
              itemInItems.product.id === item.product.id &&
              itemInItems.product.minLength === item.product.minLength &&
              itemInItems.quantity === item.quantity
            )
        })
        if(!itemExists) {
            console.log('item not found')
            return
        }

        const updatedItems = items.map(itemInItems => {
            // if(item.product.minLength === null) {
            //     if(itemInItems.product.id === item.product.id) {
            //         return {
            //             ...itemInItems,
            //             longitude: item.longitude
            //         }
            //     }
            // }

            if (itemInItems.product.id === item.product.id &&
                itemInItems.product.minLength === item.product.minLength &&
                itemInItems.quantity === item.quantity) {
                    return {
                        ...itemInItems,
                        longitude: item.longitude
                    }
                }

            return itemInItems;
        });
        console.log(updatedItems)
        setItems(updatedItems);
    }

    const removeItem = (item) => {
        const updatedItems = items.filter(itemInItems => {
            if (item.product.minLength !== null) {
                return (
                  itemInItems.product.id !== item.product.id ||
                  itemInItems.product.minLength !== item.product.minLength ||
                  itemInItems.longitude !== item.longitude
                )
              }

            return itemInItems.product.id !== item.product.id
        });
        setItems(updatedItems);
        // mixpanel.track(`Producto eliminado del carrito: ${item.product.name}`)
    }

    const getSubtotal = () => {
        return items.reduce((acc, item) => {
            const price = item.product.price;
            const quantity = item.quantity;
            
            var total = 0
            if (item.product.minLength === null) {
                total = price * quantity
            } else {
                total = price * quantity * (item.longitude / 10)
            }

            return acc + total;
        }, 0)
    }

    const getTax = () => {
        const subtotal = getSubtotal();
        return subtotal * 0.16;
    }

    const getTotal = () => {
        const subtotal = getSubtotal();
        const tax = getTax();
        return subtotal + tax;
    }

    const clearCart = () => {
        setItems([]);
    }

    const contextValues = {
        items,
        addOrRemoveItem,
        updateItemQuantity,
        removeItem,
        updateItemLongitude,
        getTax,
        getSubtotal,
        getTotal,
        clearCart
    }

    return (
        <CartContext.Provider value={contextValues}>
            {children}
        </CartContext.Provider>
    );
}

export const useCartContext = () => {
    const context = useContext(CartContext);

    if (context === undefined || context === null) {
        throw new Error("useCartContext must be used within a CartProvider");
    }

    return context;
}